<template>
  <ProgressBar :max="max" :progress="progress" :show-label="false">
    <template #title>
      <span v-if="amountLeft <= 0">
        {{ $t('checkout.free_shipping_reached') }}
      </span>
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-else
        class="[&_span]:text-primary-default"
        v-html="
          $t('checkout.x_amount_away_from_free_shipping', {
            amount: amountLeftFormatted,
          })
        "
      >
      </span>
      <!-- eslint-enable vue/no-v-html -->
    </template>
  </ProgressBar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCartStore } from '~/stores/cart';
import { useCheckoutStore } from '~/stores/checkout';

const cartStore = useCartStore();
const checkoutStore = useCheckoutStore();

const { cartAmount, cart } = storeToRefs(cartStore);
const { selectedShippingOptionSlug, shippingRate } = storeToRefs(checkoutStore);
const { $formatPrice } = useNuxtApp();

const props = defineProps({
  watchForCartChanges: {
    type: Boolean,
    default: true,
  },
});

const { watchForCartChanges } = toRefs(props);

if (watchForCartChanges.value) {
  watch(
    [cart, selectedShippingOptionSlug],
    async () => {
      if (cart.value) {
        await checkoutStore.getShippingRate(
          cartAmount.value,
          selectedShippingOptionSlug.value,
          cart.value
        );
      }
    },
    { immediate: shippingRate.value === null }
  );
}

const max = computed(
  () =>
    shippingRate.value?.amountToGetFreeShipping ??
    useShopCountryConfig().shippingRates.freeFromAmount // Fallback in case something goes wrong with the get shipping rate request
);

const amountLeft = computed(
  () =>
    shippingRate.value?.amountLeftForFreeShipping ??
    max.value - cartAmount.value // Fallback
);

const progress = computed(
  () =>
    shippingRate.value?.amountLeftForFreeShipping
      ? max.value - shippingRate.value.amountLeftForFreeShipping
      : cartAmount.value // Fallback
);

const amountLeftFormatted = computed(() => $formatPrice(amountLeft.value));
</script>
