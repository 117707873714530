<template>
  <div
    class="border-b border-black/6 bg-background-extra-light py-3"
    data-test="cart-item"
  >
    <div class="flex w-full flex-1 justify-between">
      <div class="mr-2 flex w-3/12 justify-center">
        <NuxtLink class="m-auto" :to="link">
          <img
            class="object-contain mix-blend-darken"
            style="max-height: 120px"
            :src="PrescriptionProductImage"
            :alt="description"
          />
        </NuxtLink>
      </div>

      <div class="flex w-8/12 grow flex-col">
        <div class="mb-4 flex flex-col">
          <span class="w-full text-xs">
            {{ $t('prescription.prescription_product') }}
          </span>
          <div class="mb-4">
            <div class="flex items-baseline">
              <NuxtLink
                :to="link"
                class="font-semibold text-black/87 hover:underline"
                @click="toggleSidebar(false)"
              >
                <h2 class="font-sans font-semibold">
                  {{ description }}
                </h2>
              </NuxtLink>
            </div>
            <span>
              x {{ amountOfWithdrawals }} {{ $t('prescription.packages') }}
            </span>
          </div>

          <div class="flex items-center">
            <div class="flex flex-wrap items-center justify-between">
              <div class="ml-auto">
                <span class="text-lg font-bold text-primary-default">
                  {{ $formatPrice(price) }}
                </span>
              </div>
            </div>
            <div v-if="enableBasketUpdates" class="ml-auto flex">
              <NuxtLink
                class="-mt-1 mr-2 p-2"
                :to="localePath({ name: 'shop-dashboard-prescriptions' })"
                @click="toggleSidebar(false)"
              >
                <StaticPictogram name="edit" />
              </NuxtLink>
              <button
                class="-mt-1 p-2"
                :disabled="deleteLoading"
                @click="
                  deleteLoading = true;
                  deleteItem();
                "
              >
                <DynamicPictogram
                  :name="deleteLoading ? 'spinner' : 'trash'"
                  :spin="deleteLoading"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { round } from '#root/shared/utils/math-utils';
import { usePrescriptionStore } from '~/stores/prescription';
import { useSidebarStore } from '~/stores/sidebar';
import PrescriptionProductImage from '~/assets/images/prescription-product-image.svg?url';

const localePath = useLocalePath();

const props = defineProps({
  description: {
    required: true,
    type: String,
  },
  prescriptionId: {
    required: true,
    type: Number,
  },
  price: {
    required: true,
    type: [Number],
  },
  quantity: {
    required: true,
    type: [String, Number],
  },
  quantityPerWithdrawal: {
    required: true,
    type: [String, Number],
  },
  itemNumber: {
    type: [String],
    default: '',
  },
  brandSlug: {
    type: String,
    default: '',
  },
});

const prescriptionStore = usePrescriptionStore();
const sidebarStore = useSidebarStore();
const deleteLoading = ref(false);
const enableBasketUpdates = computed(
  () => prescriptionStore.enableBasketUpdates
);
const amountOfWithdrawals = computed(() =>
  round(+props.quantity / +props.quantityPerWithdrawal)
);
const link = localePath({
  name: 'shop-prescription-brand-slug',
  params: { brand: props.brandSlug, slug: props.itemNumber },
});

const removeFromBasket = (prescriptionId: { prescriptionId: Number }) =>
  prescriptionStore.removeFromBasket(prescriptionId);
const toggleSidebar = (open: boolean) => sidebarStore.toggleSidebar(open);
const { prescriptionId } = props;

const deleteItem = async () => {
  await removeFromBasket({ prescriptionId });
  deleteLoading.value = false;
};
</script>
